import { SyntheticEvent } from "react";
import { publicRuntimeConfig } from "config";

import { t, Trans } from "@lingui/macro";

import {
  IconButton,
  MenuItem,
  MenuItemProps,
  useClipboard,
} from "@chakra-ui/react";
import { IconButtonProps, Tooltip } from "@chakra-ui/react";

import { FcCheckmark } from "@react-icons/all-files/fc/FcCheckmark";
import { FiCopy } from "@react-icons/all-files/fi/FiCopy";

import { useCanDownloadResults, useIsAdmin } from "models/keycloak";

import { Processing, ProcessingStatus } from "types/processing";

type ShareMenuProps = {
  processing: Processing;
  hideGeojsonButton?: boolean;
};

const geoJsonViewerUrl = publicRuntimeConfig.GEO_JSON_VIEWER_URL;
const keplerGlAppURL = publicRuntimeConfig.KEPLER_GL_APP_URL;

function getKeplerModelId(processing: Processing) {
  const configModel = processing?.workflowDef.id;
  // const isHeights = config?.config?.modelOptionIds.some((option) =>
  //   [ModelOptionId.BuildingsHeights, ModelOptionId.ForestHeights].includes(
  //     option
  //   )
  // );

  // return isHeights ? `${configModel}_heights` : configModel;
  return `${configModel}_heights`;
}

export const ShareMenu: React.VFC<ShareMenuProps> = ({
  processing,
  hideGeojsonButton,
}) => {
  const isAdmin = useIsAdmin();
  const canDownloadResults = useCanDownloadResults();
  const allowedDownloadResults = isAdmin || canDownloadResults;

  const origin = window?.location?.origin ?? null;
  const downloadUrl = `${publicRuntimeConfig.WHITEMAPS_URL}/rest/processings/${processing.id}/result`;
  const keplerModelId = getKeplerModelId(processing);
  const isDisabled = processing.status !== ProcessingStatus.OK;

  const keplerURL = useClipboard(
    `${keplerGlAppURL}?jobUrl=${origin}/api/processings/${processing.id}/kepler/${keplerModelId}.json`
  );

  const geojsonURL = useClipboard(
    `${geoJsonViewerUrl},${encodeURIComponent(downloadUrl)}`
  );

  return (
    <>
      {!hideGeojsonButton && (
        <MenuLink
          url={downloadUrl}
          isDisabled={isDisabled}
          isAllowed={allowedDownloadResults}
        >
          <Trans>Download GeoJSON</Trans>
        </MenuLink>
      )}

      <MenuLink
        url={geojsonURL.value}
        isDisabled={isDisabled}
        isAllowed={allowedDownloadResults}
        defaultIcon={{
          icon: geojsonURL.hasCopied ? <FcCheckmark /> : <FiCopy />,
          props: {
            "aria-label": "Copy",
            onClick: withPrevent(geojsonURL.onCopy),
          },
        }}
      >
        <Trans>Open with geojson.io</Trans>
      </MenuLink>

      <MenuLink
        url={keplerURL.value}
        isDisabled={isDisabled}
        isAllowed={allowedDownloadResults}
        defaultIcon={{
          icon: keplerURL.hasCopied ? <FcCheckmark /> : <FiCopy />,
          props: {
            "aria-label": "Copy",
            onClick: withPrevent(keplerURL.onCopy),
          },
        }}
      >
        <Trans>Open with kepler.gl</Trans>
      </MenuLink>
    </>
  );
};

function withPrevent<T extends SyntheticEvent>(fn: () => void) {
  return (e: T) => {
    e.preventDefault();
    e.stopPropagation();
    fn();
  };
}

type BaseButtonProps = MenuItemProps & {
  url: string;
  isDisabled?: boolean;
  isAllowed?: boolean;
  children?: React.ReactNode;
  defaultIcon?: {
    icon: React.ReactElement;
    props: IconButtonProps;
  };
};

function MenuLink({
  url,
  isDisabled,
  isAllowed,
  children,
  defaultIcon,
  ...props
}: BaseButtonProps) {
  const isDis = isDisabled || !isAllowed;

  const item = (
    <MenuItem
      fontSize="0.875rem"
      as={isAllowed ? "a" : "div"}
      download
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      isDisabled={isDis}
      {...props}
    >
      {children}
      {isAllowed && defaultIcon?.icon && (
        <IconButton
          ml="auto"
          variant="ghost"
          size="sm"
          icon={defaultIcon.icon}
          _hover={
            isDis ? { cursor: "not-allowed" } : { backgroundColor: "#CBD5E0" }
          }
          {...defaultIcon.props}
        />
      )}
    </MenuItem>
  );

  if (!isAllowed) {
    return (
      <Tooltip label={t`This feature is not available under the free account`}>
        {item}
      </Tooltip>
    );
  }

  return <>{item}</>;
}
