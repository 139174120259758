import React, { VFC } from "react";

import { Trans } from "@lingui/macro";

import { Box, Heading, Stack } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/modal";
import { IconButton, Tooltip, useClipboard } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/textarea";

import { FcCheckmark } from "@react-icons/all-files/fc/FcCheckmark";
import { FiCopy } from "@react-icons/all-files/fi/FiCopy";
import { FiDownload } from "@react-icons/all-files/fi/FiDownload";

import { useTileFromCOG } from "shared/api/client/hook";
import { useDownloadAsFile } from "shared/lib/hooks/use-download-as-file";

import { Processing, ProcessingAoi } from "types/processing";

type Props = Omit<ModalProps, "children"> & ModalInnerProps;

type ModalInnerProps = {
  processing: Processing;
  processingId: string;
  rasterLayer?: Processing["rasterLayer"];
  processingAoi: ProcessingAoi[];
};

const ModalProcessingDetails: VFC<Props> = ({
  processing,
  rasterLayer,
  processingId,
  processingAoi,
  ...modalProps
}) => {
  return (
    <Modal {...modalProps}>
      <ModalOverlay />
      <ModalInner
        processing={processing}
        rasterLayer={rasterLayer}
        processingId={processingId}
        processingAoi={processingAoi}
      />
    </Modal>
  );
};

// -- separate component for request only mount

const ModalInner: React.FC<ModalInnerProps> = ({
  processing,
  rasterLayer,
  processingId,
  processingAoi,
}) => {
  const sourceUrl = cleanupURL(processing.params.url!);
  const geometry = processingAoi[0].geometry;
  const aoiRaw = JSON.stringify(geometry);

  const tileQuery = useTileFromCOG(processingId, rasterLayer.tileUrl);
  const isTiff =
    processing.params.source_type === "local" && rasterLayer.tileUrl;
  const url = isTiff ? tileQuery.data || "" : sourceUrl;

  const value = url ? url : processing.params.data_provider;

  const onDownloadAoi = useDownloadAsFile({
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry,
        },
      ],
    },
    filename: "aoi.json",
  });

  const { onCopy: onCopyAoi, hasCopied: hasAoiCopied } = useClipboard(aoiRaw);
  const { onCopy: onCopyUrl, hasCopied: hasUrlCopied } = useClipboard(url);

  return (
    <ModalContent borderRadius="ui-base">
      <ModalHeader>
        <Trans>Processing source details</Trans>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={5}>
          <Stack>
            <Stack isInline alignItems="center" justifyContent="space-between">
              <Heading size="sm">
                <Trans>Area of interest</Trans>
              </Heading>
              <Box display="flex" ml="auto" position="relative">
                <Tooltip aria-label="Download" label="Download">
                  <Box>
                    <IconButton
                      variant="ghost"
                      onClick={onDownloadAoi}
                      icon={<FiDownload />}
                      aria-label="Download"
                    />
                  </Box>
                </Tooltip>

                <Tooltip aria-label="Copy" label="Copy">
                  <Box>
                    <IconButton
                      variant="ghost"
                      icon={hasAoiCopied ? <FcCheckmark /> : <FiCopy />}
                      onClick={onCopyAoi}
                      aria-label="Copy"
                    />
                  </Box>
                </Tooltip>
              </Box>
            </Stack>
            <Textarea isReadOnly value={aoiRaw} />
          </Stack>
          <Stack>
            <Stack isInline alignItems="center" justifyContent="space-between">
              <Heading size="sm">
                <Trans>Source image url</Trans>
              </Heading>
              <Box ml="auto">
                <Tooltip aria-label="Copy" label="Copy">
                  <Box>
                    <IconButton
                      variant="ghost"
                      icon={hasUrlCopied ? <FcCheckmark /> : <FiCopy />}
                      onClick={onCopyUrl}
                      aria-label="Copy"
                    />
                  </Box>
                </Tooltip>
              </Box>
            </Stack>
            <Textarea isReadOnly value={value} />
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter />
    </ModalContent>
  );
};

const cleanupURL = (inputURL: string) => {
  return inputURL?.replaceAll(/(=?(&|\?))(token|access_token)=(.+)/gi, "");
  // const separatorIndex = inputURL.indexOf("#");

  // const url = inputURL.slice(
  //   0,
  //   separatorIndex === -1 ? inputURL.length : separatorIndex
  // );

  // return url.split("?")[0];
};

export default ModalProcessingDetails;
