import { MouseEvent, useEffect, useMemo } from "react";

import { t } from "@lingui/macro";

import { Box, Flex, Progress, Text, usePrevious } from "@chakra-ui/react";

import { AiFillCheckCircle } from "@react-icons/all-files/ai/AiFillCheckCircle";
import { IoIosRemoveCircle } from "@react-icons/all-files/io/IoIosRemoveCircle";

import { noop } from "shared/lib/fn";

import { ProcessingStatus, Stage, StageName } from "types";

const colorByStatus: Record<ProcessingStatus, string> = {
  [ProcessingStatus.OK]: "green",
  [ProcessingStatus.INPROGRESS]: "blue",
  [ProcessingStatus.PENDING]: "yellow",
  [ProcessingStatus.FAILED]: "red",
};

export const stageNameTranslations: Record<StageName, any> = {
  "select-source": t`Preparing imagery`,
  "validate-source": t`Validating source`,
  dataloader: t`Downloading data`,
  inference: t`AI works`,
  "build-cog": t`Preparing imagery`,
  "import-vector": t`Saving results`,
  "user-input": t`Magic here`,
};

type Props = {
  status: ProcessingStatus;
  // stages: Stage[];
  onFailedStatusClick: () => void;
  isHasMessage: boolean;
  isErrorVisible: boolean;
  percent: number | null;
};

const LoadingStatuses = [ProcessingStatus.INPROGRESS, ProcessingStatus.PENDING];

const ProgressTag: React.VFC<Props> = ({
  status,
  onFailedStatusClick,
  isHasMessage,
  isErrorVisible,
  percent,
}) => {
  // const { completed, count, failedStage } = useMemo(() => {
  //   return {
  //     completed: stages.filter(({ status }) => status === ProcessingStatus.OK)
  //       .length,
  //     count: stages.length,
  //     failedStage: stages.find(
  //       ({ status }) => status === ProcessingStatus.FAILED
  //     ),
  //   };
  // }, [stages]);

  // const value = useMemo(
  //   () => Math.floor((100 * completed) / count),
  //   [completed, count]
  // );

  // const prevStatus = usePrevious(status);

  const isAnimated = LoadingStatuses.includes(status);

  if (status === ProcessingStatus.FAILED) {
    return (
      <FailedStatus
        icon={IoIosRemoveCircle}
        color="var(--mapflow-error)"
        text={status}
        // openOnMount={LoadingStatuses.includes(prevStatus)}
        onClick={onFailedStatusClick}
        isHasMessage={isHasMessage}
        isOpen={isErrorVisible}
      />
    );
  }

  if (status === ProcessingStatus.OK) {
    return (
      <StatusWithIcon
        icon={AiFillCheckCircle}
        color="green.500"
        text={ProcessingStatus.OK}
      />
    );
  }

  // const currentActiveStage = stages.find(
  //   ({ status }) => status === ProcessingStatus.INPROGRESS
  // );

  const percentCompleted = percent ? percent : 0;

  return (
    <>
      <Box minW="200px">
        <Progress
          height="6px"
          isAnimated={isAnimated}
          hasStripe={isAnimated}
          borderRadius="ui-base"
          colorScheme={colorByStatus[status]}
          value={Math.max(10, percentCompleted)}
        />
        <Box mt={1} display="flex" justifyContent="space-between">
          <Text fontSize="12px">{t`Pending`}</Text>
          <Text fontSize="12px">{percentCompleted}%</Text>
        </Box>
      </Box>
    </>
  );
};

type StatusWithIconProps = {
  icon: any;
  text: ProcessingStatus | string;
  color: string;
};

const StatusWithIcon: React.VFC<StatusWithIconProps> = ({
  icon,
  color,
  text,
}) => {
  return (
    <Box display="flex" w="fit-content" alignItems="center">
      <Box w="24px" h="24px" as={icon} color={color} />
      <Text ml="8px" color={color} fontSize="12px">
        {text}
      </Text>
    </Box>
  );
};

type TFailedStatus = StatusWithIconProps & {
  onClick: () => void;
  isOpen: boolean;
  isHasMessage?: boolean;
  openOnMount?: boolean;
};

const FailedStatus: React.VFC<TFailedStatus> = ({
  icon,
  color,
  isOpen,
  onClick,
  openOnMount = false,
  isHasMessage,
  text,
}) => {
  useEffect(() => {
    if (openOnMount) setTimeout(onClick, 400);
  }, [openOnMount]);

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <Flex flexDirection="column" float="left" maxW="250px">
      <Box
        display="flex"
        w="fit-content"
        alignItems="center"
        cursor={isHasMessage ? "pointer" : "default"}
        onClick={isHasMessage ? handleClick : noop}
      >
        <Box w="24px" h="24px" as={icon} color={color} />
        {isHasMessage && (
          <Box
            ml="1px"
            boxSize={0}
            borderTop="5px solid transparent"
            borderBottom="5px solid transparent"
            borderLeft="8px solid #b62626"
            borderRadius="100%"
            transition="transform 0.3s"
            transform={isOpen ? "rotate(90deg)" : ""}
          />
        )}

        <Text ml={1} color={color} fontSize="12px" userSelect="none">
          {text}
        </Text>
      </Box>
    </Flex>
  );
};

export default ProgressTag;
