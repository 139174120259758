import { FC, useEffect, useMemo, useState } from "react";

import { AxiosResponse } from "axios";
import { useQueryClient } from "react-query";

import styled from "@emotion/styled";
import { t } from "@lingui/macro";

import { Editable, EditableInput, EditablePreview } from "@chakra-ui/editable";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { CircularProgress } from "@chakra-ui/progress";
import { useDisclosure } from "@chakra-ui/react";

import { AiFillStar } from "@react-icons/all-files/ai/AiFillStar";

import { Buildings } from "components/icons/buildings";
import { CityScape } from "components/icons/cityscape";
import { Crane } from "components/icons/crane";
import { Forest } from "components/icons/forest";
import { Road } from "components/icons/road";
import { SegmentAnything } from "components/icons/segment-anything";
import { Tractor } from "components/icons/tractor";
import { UavHouse } from "components/icons/uav-house";

import { updateProcessing } from "shared/api/client";
import { useEnchancedMutation } from "shared/lib/hooks/use-enchanced-mutation";
import { toOrderList, translateErrorsMessages } from "shared/lib/i18n/errors";

import { ModelId, Processing, ProcessingAoi, ProcessingStatus } from "types";

import CardMenu, { CardMenuProps } from "./card-menu";
import ProgressTag from "./progress-tag";
import { ErrorCollapse } from "./progress-tag/error-collapse";
import { RightAddon } from "./progress-tag/right-addon";

export const iconsMapByModelId: Record<ModelId, FC> = {
  [ModelId.DenseBuildings]: CityScape,
  [ModelId.Buildings]: Buildings,
  [ModelId.Forest]: Forest,
  [ModelId.Roads]: Road,
  [ModelId.Constructions]: Crane,
  [ModelId.Fields]: Tractor,
  [ModelId.UavBuildings]: UavHouse,
  [ModelId.SAM]: SegmentAnything,
};

type OutCollapseProps = CardMenuProps & {
  handleClick: () => void;
  processing: Processing;
  isMapView: boolean;
};

const OutCollapse: React.VFC<OutCollapseProps> = ({
  processing,
  handleClick,
  isMapView,
}) => {
  const queryClient = useQueryClient();

  const orderList = useMemo(
    () => toOrderList(translateErrorsMessages(processing.messages)),
    [processing.messages]
  );

  const control = useDisclosure();

  const [name, setName] = useState(processing.name);

  const resetName = () => setName(processing.name);
  useEffect(() => setName(processing.name), [processing.name]);

  // const Icon = iconsMapByModelId[processing.config.modelId];

  const { mutate: mutationUpdate, isLoading } = useEnchancedMutation<
    AxiosResponse,
    unknown,
    string
  >({
    mutationFunction: (name) =>
      updateProcessing(processing.id, {
        name,
        description: processing.description,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["processings", processing.projectId]);
    },
    onError: resetName,
    toast: {
      msgSuccess: t`Processing updated`,
      msgError: t`Failed update processing`,
    },
  });

  const handleSubmit = (newName: string) => {
    const trimmedName = newName.trim();
    if (!trimmedName || processing.name === trimmedName) {
      resetName();
      return;
    }
    mutationUpdate(trimmedName);
  };

  const isHasMessage = Boolean(orderList);
  const stars = processing?.rating ? processing.rating : null;

  return (
    <OutCollapseCard
      onClick={handleClick}
      cursor={
        isMapView || processing.status === ProcessingStatus.FAILED
          ? "default"
          : "pointer"
      }
    >
      <Header>
        <Flex alignItems="center">
          <Editable
            value={name}
            onClick={(e) => e.stopPropagation()}
            onSubmit={handleSubmit}
            isDisabled={isLoading}
            onChange={setName}
            h="32px"
            maxH="32px"
          >
            <EditablePreview
              isTruncated
              maxW="240px"
              // px="6px"
              fontSize="16px"
              fontWeight="bold"
            />
            <EditableInput
              w="240px"
              maxLength={30}
              px="6px"
              value={undefined}
            />
          </Editable>
        </Flex>
        <Flex alignItems="center">
          {isLoading && (
            <CircularProgress size="24px" capIsRound isIndeterminate />
          )}
          {!stars ? null : (
            <Flex ml={1} mr={2} alignItems="center">
              <Box as={AiFillStar} color="orange.300" w="14px" h="14px" />
              <Text ml={1} color="orange.300" fontSize="14px">
                {stars?.rating}
              </Text>
            </Flex>
          )}
          <Box onClick={(e) => e.stopPropagation()}>
            <CardMenu processing={processing} />
          </Box>
        </Flex>
      </Header>
      <Box isTruncated width={"100%"} fontSize="14px" fontWeight="medium">
        {processing.workflowDef.name}
      </Box>
      <Flex justifyContent="space-between" mt={4}>
        <ProgressTag
          // stages={processing.progress.stages}
          status={processing.status}
          percent={processing.percentCompleted}
          isErrorVisible={control.isOpen}
          isHasMessage={isHasMessage}
          onFailedStatusClick={control.onToggle}
        />

        <RightAddon
          cost={processing.cost}
          created={processing.created}
          status={processing.status}
        />
      </Flex>
      <ErrorCollapse
        isOpen={control.isOpen}
        isHasMessage={isHasMessage}
        message={orderList}
      />
    </OutCollapseCard>
  );
};

const OutCollapseCard = styled(Box)`
  border-radius: var(--chakra-radii-ui-base);
  min-height: inherit;
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default OutCollapse;
